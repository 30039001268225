<template>
	<div class="px-2">
		<v-scroll-y-reverse-transition>
			<v-menu v-if="!lock" class="mb-2" :value="p.show" top :offset-y="true" left>
				<template v-slot:activator="{ attrs, on }">
					<v-card
						height="75"
						:color="$vuetify.theme.dark ? '' : 'primary'"
						class="d-flex flex-column justify-center mx-auto mb-1"
						style="aspect-ratio: 1;"
						@click="cambiarEntorno"
						v-bind="attrs"
						v-on="on"
					>
						<div class="d-flex justify-center">
							<v-icon color="white">
								{{
									$store.getters.getEntorno == "Luz" ? "mdi-lightning-bolt" : "mdi-fire"
								}}
							</v-icon>
						</div>
						<div class="text-center white--text">{{ $store.getters.getEntorno }}</div>
					</v-card>
				</template>

				<v-card :elevation="0" class="pa-2" v-click-outside="p.reject">
					¿Quieres cambiar de entorno?
					<v-card-actions class="pa-0 mt-4">
						<v-spacer />
						<v-btn @click="p.reject" text color="error">NO</v-btn>
						<v-btn @click="p.resolve" text color="primary">SI</v-btn>
					</v-card-actions>
				</v-card>
			</v-menu>

			<v-card
				height="75"
				:color="$vuetify.theme.dark ? '' : 'primary'"
				class="d-flex flex-column justify-center mx-auto mb-1 elevation-0"
				style="aspect-ratio: 1;"
				v-else
			>
				<div class="d-flex justify-center">
					<v-icon color="white">
						{{
							$store.getters.getEntorno == "Luz" ? "mdi-lightning-bolt" : "mdi-fire"
						}}
					</v-icon>
				</div>
				<div class="text-center white--text">{{ $store.getters.getEntorno }}</div>
			</v-card>
		</v-scroll-y-reverse-transition>
	</div>
</template>

<script>
export default {
	data() {
		return {
			p: {
				show: false,
				resolve: null,
				reject: null,
			},
			lock: false
		};
	},
	async mounted() {
		const { data: entornoCuenta } = await axios({
			url: `${process.env.VUE_APP_OUR_API_URL}/entornoUsuario.php`,
			method: 'POST',
			data: {
				idUsuario: this.$store.getters.getDecodedJwtEmpresa.idUsuario
			}
		});

		this.lock = !Number(entornoCuenta.dual);
	},
	methods: {
		async cambiarEntorno() {
			try {
				await new Promise((resolve, reject) => {
					this.p.show = true;
					this.p.resolve = resolve;
					this.p.reject = reject;
				});

				this.$store.dispatch("cambiarEntorno");
				this.p.show = false;
			} catch (e) {
				this.p.show = false;
				this.p.resolve = null;
				this.p.reject = null;
			}
		},
	},
};
</script>

<style scoped>
.triangle {
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;

	border-top: 10px solid white;
	margin: 0 auto;
}
</style>